import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Login from '../../assets/images/login.png'
import { Link } from 'react-router-dom';
import Google from '../../assets/images/Google.svg'
import CloseIcon from "mdi-material-ui/Close";
import DialogForgetLogin from './DialogForgetLogin';
import { axiosInstance } from 'src/network/adapter';
import { ApiEndpoints } from 'src/network/endpoints';
import { toastError, toastSuccess } from 'src/utils/utils';
import { useAuth } from 'src/hooks/useAuth';
import { checkAuthentication } from 'src/redux/auth.Slice';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { LoadingButton } from '@mui/lab';
// import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';


const validationSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email is required")
        .max(50, "The email should have at most 50 characters")
        .matches(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            "Email address must be a valid address"
        ),
    password: yup
        .string()
        .required("Password is required")
});

const DialogLogin = props => {
    const auth = useAuth()
    const dispatch = useDispatch();
    const { open, toggle, setRegisterFormDialogOpen, setLoginFormDialogOpen } = props
    const [loading, setLoading] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const toggleRegisterFormDialog = (e) => {
        setLoginFormDialogOpen(false)
        setRegisterFormDialogOpen(true)
    }
    const [forgetFormDialogOpen, setForgetFormDialogOpen] = useState(false)
    const toggleForgetFormDialog = () => {
        setLoginFormDialogOpen(false)
        setForgetFormDialogOpen(!forgetFormDialogOpen)
    }
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })
    const onSubmit = data => {
        setLoading(true);
        let payload = {
            email: data.email,
            password: data.password
        }
        axiosInstance
            .post(ApiEndpoints.AUTH.login, payload)
            .then((response) => response.data)
            .then((response) => {
                toastSuccess(response.message);
                toggle()
                auth.login(response)
                dispatch(checkAuthentication());
                reset({ email: '', password: '' })
                //onSuccess()
                //console.log(response.data.token);
            })
            .catch((error) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log("Login successful", tokenResponse.access_token)
            let payload = {
                token: tokenResponse.access_token,
            }
            axiosInstance
                .post(ApiEndpoints.AUTH.googleLogin, payload)
                .then((response) => response.data)
                .then((response) => {

                    toastSuccess(response.message);
                    auth.login(response)
                    dispatch(checkAuthentication());
                    setLoginFormDialogOpen(false)
                    //onSuccess()
                    //console.log(response.data.token);
                })
                .catch((error) => {
                    toastError(error);
                })
                .finally(() => {
                    setLoading(false);
                })

        },
        onError: (error) => {
            console.error('Login Failed:', error);
        },
    });

    return (
        <>
            <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '30px' } }}
                open={open} fullWidth maxWidth='md' scroll='paper'>
                <DialogContent>
                    <form id='login' onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <img src={Login} alt={''} style={{ width: '100%', height: '100%' }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{
                                        fontFamily: 'Ivy Mode ,sans-serif',
                                        fontWeight: 400,
                                        fontSize: { xs: '24px', md: '30px' },
                                        lineHeight: { xs: '32px', md: '37px' },
                                        color: '#5B2E1E'
                                    }}>Sign in to Rug Auction</Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={toggle}
                                        sx={{ color: (theme) => theme.palette.grey[500] }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ mb: '20px' }} />
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField size='medium'
                                                            error={Boolean(errors.email)}
                                                            {...field}
                                                            placeholder="Enter your email"
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                                {errors.email && (
                                                    <FormHelperText sx={{ color: "#ff0000" }}>
                                                        {errors.email.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl fullWidth >
                                                <Controller
                                                    name='password'
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <TextField
                                                            variant='outlined'
                                                            error={Boolean(errors.password)}
                                                            value={value}
                                                            onChange={onChange}
                                                            autoFocus
                                                            id='password'
                                                            placeholder='Enter your New Password'
                                                            type={showNewPassword ? 'text' : 'password'}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                                            onMouseDown={e => e.preventDefault()}
                                                                        >
                                                                            {showNewPassword ? <VisibilityOutlinedIcon sx={{ color: '#905A47' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#905A47' }} />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.password && (
                                                    <FormHelperText error>
                                                        {errors.password.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-end' }}
                                            >
                                                <Button as={Link} onClick={toggleForgetFormDialog}>
                                                    Forgot Password?
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <LoadingButton
                                                size="small"
                                                sx={{ width: '100%', bgcolor: '#000000' }}
                                                type="submit"
                                                form="login"
                                                variant="contained"
                                                loading={loading}
                                            >
                                                {loading ? "Submitting..." : "Sign In"}
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                <Typography variant='body2'>
                                                    Don’t have an account?
                                                </Typography>
                                                <Typography variant='body2'>
                                                    <Button variant='text' onClick={toggleRegisterFormDialog}>
                                                        Sign up for free
                                                    </Button>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider flexItem>OR</Divider>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Button fullWidth
                                                variant="outlined"
                                                onClick={login}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                    borderRadius: '52px',
                                                    padding: '10px 20px',
                                                    textTransform: 'none',
                                                    borderColor: '#905A47',
                                                    color: '#905A47',
                                                    '&:hover': {
                                                        borderColor: '#905A47',
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <img src={Google} alt={'Google Logo'} style={{ width: 24, height: 24 }} />
                                                    <Typography variant='fm-p2' color={'#000000'}>Login with Google</Typography>
                                                </Box>
                                            </Button>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12}>
                                        <Button fullWidth
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 1,
                                                borderRadius: '52px',
                                                padding: '10px 20px',
                                                textTransform: 'none',
                                                borderColor: '#905A47',
                                                color: '#905A47',
                                                '&:hover': {
                                                    borderColor: '#905A47',
                                                    backgroundColor: '#f5f5f5',
                                                },
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <img src={Facebook} alt={'Google Logo'} style={{ width: 24, height: 24 }} />
                                                <Typography variant='fm-p2' color={'#000000'}>Login with Facebook</Typography>
                                            </Box>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Button fullWidth
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 1,
                                                borderRadius: '52px',
                                                padding: '10px 20px',
                                                textTransform: 'none',
                                                borderColor: '#905A47',
                                                color: '#905A47',
                                                '&:hover': {
                                                    borderColor: '#905A47',
                                                    backgroundColor: '#f5f5f5',
                                                },
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <img src={Apple} alt={'Google Logo'} style={{ width: 24, height: 24 }} />
                                                <Typography variant='fm-p2' color={'#000000'}>Login with Apple</Typography>
                                            </Box>
                                        </Button>
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <DialogForgetLogin
                open={forgetFormDialogOpen}
                toggle={toggleForgetFormDialog}
                setLoginFormDialogOpen={setLoginFormDialogOpen}
                setForgetFormDialogOpen={setForgetFormDialogOpen}
            />
        </>
    )
}

export default DialogLogin

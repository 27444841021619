export const API_BASE_URL = 'https://api.dev.rugauction.com'
//export const API_BASE_URL = "http://192.168.29.55:3000"
export const MEDIA_URL = 'https://d1ysfw85jlu7hq.cloudfront.net'

export const ApiEndpoints = {
  AUTH: {
    create: `${API_BASE_URL}/api/v1/auth/register`,
    login: `${API_BASE_URL}/api/v1/auth/login`,
    me: `${API_BASE_URL}/api/v1/auth/me`,
    forgetPassword: `${API_BASE_URL}/api/v1/auth/password/forgot`,
    resetPassword: `${API_BASE_URL}/api/v1/auth/password/reset`,
    profile: `${API_BASE_URL}/api/v1/auth/profile`,
    googleLogin: `${API_BASE_URL}/api/v1/auth/google`
  },
  RUGS: {
    list: `${API_BASE_URL}/api/v1/rug`,
    listbyid: (id) => `${API_BASE_URL}/api/v1/rug/${id}`,
    offer: (id) => `${API_BASE_URL}/api/v1/rug/add-offer/${id}`,
    like: `${API_BASE_URL}/api/v1/rug/like/list`,
    myitem: `${API_BASE_URL}/api/v1/rug/winner/list`,
    offerList: `${API_BASE_URL}/api/v1/rug/offer/list`
  },
  AUCTION: {
    list: `${API_BASE_URL}/api/v1/rug/upcoming/list`,
    listbyid: id => `${API_BASE_URL}/api/v1/rug/upcoming/${id}`,
  },
  NOTIFY: {
    Create: `${API_BASE_URL}/api/v1/auth/notify`
  },
  Like: {
    send: (id) => `${API_BASE_URL}/api/v1/rug/auction/${id}`
  },
  TESTIMONIAL: {
    list: `${API_BASE_URL}/api/v1/misc/testimonials`
  },
  PRIVACY_POLICY: {
    list: `${API_BASE_URL}/api/v1/auth/privacypolicy`,
  },
  TERMS: {
    list: `${API_BASE_URL}/api/v1/auth/terms`
  },
  CONTACTUS: {
    add: `${API_BASE_URL}/api/v1/auth/contactus`
  },
  FAQ: {
    list: `${API_BASE_URL}/api/v1/auth/faq`
  },
  CAREER: {
    list: `${API_BASE_URL}/api/v1/misc/careers`
  },
  BLOG: {
    list: `${API_BASE_URL}/api/v1/auth/blog`,
    listbyid: id => `${API_BASE_URL}/api/v1/auth/blog/${id}`
  },
  GET_REGION: {
    country: `${API_BASE_URL}/api/v1/auth/countries`,
    state: `${API_BASE_URL}/api/v1/auth/state`,
    city: `${API_BASE_URL}/api/v1/auth/cities`
  },
  COLLECTION: {
    list: `${API_BASE_URL}/api/v1/auth/collection/list`,
  },
  NOTIFICATIONS: {
    get_notifications: `${API_BASE_URL}/api/v1/notification`,
    sent_fcm_token: `${API_BASE_URL}/api/v1/notification/token`,
    get_unread_notification_count: `${API_BASE_URL}/api/v1/notification/unread/count`,
    read_notification: `${API_BASE_URL}/api/v1/notification/read`,
  },
}
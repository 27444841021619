import React, { useEffect, useState } from 'react'
import {
    Container,
    Box,
    Typography,
    Grid,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Instagram from '../../assets/images/instagram.png'
import Facebook from '../../assets/images/facebook.png'
import Pinterest from '../../assets/images/pinterest.png'
import Linkin from '../../assets/images/linkin.png'
import Youtube from '../../assets/images/youtube.png'
import Logo from 'src/@core/components/logo'
import FooterImage from '../../assets/images/footer.png'
import ContactForm from './ContactForm'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError } from 'src/utils/utils'
const Typo = {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
    lineHeight: '13px',
}
const Footer = () => {
    const [collectionData, setCollectionData] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchData = () => {
        setLoading(true)
        axiosInstance
            .get(ApiEndpoints.COLLECTION.list,)
            .then(response => {
                setCollectionData(response.data)
            })
            .catch(error => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        fetchData()
    }, []);
    return (
        <>
            <Box sx={{ bgcolor: "#F3F0EB", pt: 15, pb: 5, padding: { xs: '20px', sm: '30px', md: '50px' } }}>
                <Container maxWidth='xl'  >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }} >
                            <Logo />
                            <Box sx={{ width: '290px', textWrap: 'wrap' }}>
                                <Typography variant='fm-p3' fontWeight={'500'} color={'#000000'} lineHeight={'19px'}>Since 2010, Rug Auctions has been offering fine, rare, vintage, antique and modern decorative rugs to discerning customers and collectors worldwide.</Typography>
                            </Box>
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif', }}>Socials</Typography>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Link to='' style={{ textDecoration: 'none' }}> <img src={Instagram} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to='' style={{ textDecoration: 'none' }}><img src={Facebook} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to='' style={{ textDecoration: 'none' }}> <img src={Pinterest} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to='' style={{ textDecoration: 'none' }}> <img src={Linkin} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                                <Link to='' style={{ textDecoration: 'none' }}> <img src={Youtube} style={{ width: '44px', height: '44px' }} alt="" /></Link>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6} md={2} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} >
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular', marginTop: { xs: '10px', sm: '10px', md: '0px' } }}>Quick Links</Typography>
                            <Link to='/about-us' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>About Us</Typography></Link>
                            <Link to='/upcoming-auctions' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>Upcoming auctions</Typography></Link>
                            <Link to='/auction-blog' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>Auctions Blogs</Typography></Link>
                            <Link to='/faq' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>FAQs</Typography></Link>
                            <Link to='/contact-us' style={{ textDecoration: 'none' }}> <Typography sx={{ ...Typo }}>Contact Us</Typography></Link>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}  >
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif', marginTop: { xs: '10px', sm: '10px', md: '0px' } }}>Category</Typography>
                            {collectionData?.data?.length ? (
                                collectionData.data.slice(0,6).map((collection, index) => (
                                    <Link   key={index} to='/collections' style={{ textDecoration: 'none' }}> <Typography key={index} sx={{ ...Typo }}>{collection?.title}</Typography></Link>
                                ))
                            ) : (
                                <Typography>No collections available</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} >
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif', marginTop: { xs: '10px', sm: '10px', md: '0px' } }}>Get Styling Assistance</Typography>
                            <ContactForm />
                        </Grid>
                    </Grid>
                    <Box mt={'50px'}>
                        <img src={FooterImage} alt="" style={{ width: '100%' }} />
                    </Box>
                    <Grid container direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={9}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '30px',
                                    color: "#361B12",
                                    textAlign: { xs: 'center', sm: 'left' }
                                }}
                            >
                                © {new Date().getFullYear()} Rugauctions All Rights Reserved.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, mb: { xs: 2, sm: 0 } }}>
                            <Box sx={{ display: "flex", gap: 7 }}>
                                <Link to='/privacy-policy' style={{ textDecoration: 'none' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '30px', color: "#361B12" }}>
                                        Privacy Policy
                                    </Typography>
                                </Link>
                                <Link to='/terms-and-condition' style={{ textDecoration: 'none' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '30px', color: "#361B12" }}>
                                        Terms and Condition
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
        </>
    )
}

export default Footer
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from "mdi-material-ui/Close";
import DialogResetPassword from './DialogResetPassword';
import { axiosInstance } from 'src/network/adapter';
import { ApiEndpoints } from 'src/network/endpoints';
import { toastError, toastSuccess } from 'src/utils/utils';


const validationSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email is required")
        .max(50, "The email should have at most 50 characters")
        .matches(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            "Email address must be a valid address"
        ),
});

const DialogForgetLogin = props => {
    const { open, toggle, setForgetFormDialogOpen ,setLoginFormDialogOpen} = props
    const [loading, setLoading] = useState(false)
    const [email,SetEmail] = useState('')
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })
    const onSubmit = data => {
        setLoading(true);
        let payload = {
            email: data.email,
        }
        axiosInstance
            .post(ApiEndpoints.AUTH.forgetPassword, payload)
            .then((response) => response.data)
            .then((response) => {
                toastSuccess(response.message);
                SetEmail(data.email);
                setResetDialogFormDialogOpen(true);
                //console.log(response.data.token);
            })
            .catch((error) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
                reset({email:''}) 
            })
    }

    const [resetDialogFormDialogOpen, setResetDialogFormDialogOpen] = useState(false)
    const toggleResetPasswordFormDialog = (e) => {
        setResetDialogFormDialogOpen(prev => !prev)
        setForgetFormDialogOpen(false)
        //setResetDialogFormDialogOpen(true)
    }
    // Reset the form when the dialog opens
    useEffect(() => {
        if (open) {
            reset({
                email: '',
                password: ''
            });
        }
    }, [open, reset]);
    return (
        <>
            <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '30px' } }}
                open={open} fullWidth maxWidth='xs' scroll='paper'>
                <DialogContent>
                    <form id='forgetPassword' onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{
                                    fontFamily: 'Ivy Mode ,sans-serif',
                                    fontWeight: 400,
                                    fontSize: { xs: '24px', md: '30px' },
                                    lineHeight: { xs: '32px', md: '37px' },
                                    color: '#5B2E1E'
                                }}>Forgot Password</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={toggle}
                                    sx={{ color: (theme) => theme.palette.grey[500] }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Divider sx={{ mb: '20px' }} />
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField size='medium'
                                                        error={Boolean(errors.email)}
                                                        {...field}
                                                        placeholder="Enter your email"
                                                      variant='outlined'
                                                    />
                                                )}
                                            />
                                            {errors.email && (
                                                <FormHelperText sx={{ color: "#ff0000" }}>
                                                    {errors.email.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                    <Button
                                        size="small"
                                        sx={{ width: '100%', bgcolor: '#000000' }}
                                        type='submit'
                                        variant='contained'
                                        form='forgetPassword'
                                        disabled={loading} 
                                    >
                                        {loading ? 'Loading...' : 'Reset'}
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </form>
                </DialogContent>
            </Dialog>

            <DialogResetPassword
                open={resetDialogFormDialogOpen}
                toggle={toggleResetPasswordFormDialog}
                email={email}
                setLoginFormDialogOpen={setLoginFormDialogOpen}
                setResetDialogFormDialogOpen={setResetDialogFormDialogOpen}
                setForgetFormDialogOpen={setForgetFormDialogOpen}
            />
        </>
    )
}

export default DialogForgetLogin

import React, { Suspense, useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
import AuthLayout from './layouts/AuthLayout'
import GuestGuard from './@core/components/auth/GuestGuard'
import AuthGuard from './@core/components/auth/AuthGuard'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './hooks/useAuth'
import {Box, Typography } from '@mui/material'
import { getNotificationPermission, messaging } from './notification/firebase'
import { onMessage } from 'firebase/messaging'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import toast from "react-hot-toast";
import BellOutline from 'mdi-material-ui/BellOutline'


const HomePage = React.lazy(() => import('./pages/home'))
const RugListingPage = React.lazy(() => import('./pages/ruglisting'))
const RugsdetailsPage = React.lazy(() => import('./pages/home/rugsdetails'))
const UpcomingAuctionsPage = React.lazy(() => import('./pages/upcomingAuctions'))
const UpcomingAuctionsDetailPage = React.lazy(() => import('./pages/upcomingAuctions/Auctiondetail/index'))
const AboutusPage = React.lazy(() => import('./pages/aboutus'))
const ContactPage = React.lazy(() => import('./pages/contact'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsandconditionPage = React.lazy(() => import('./pages/Termsandcondition'))
const FaqDetailsPage = React.lazy(() => import('./pages/faq'))
const AuctionBlogPage = React.lazy(() => import('./pages/auctionblog'))
const AuctionsBlogDetailPage = React.lazy(() => import('./pages/auctionblog/auctionblogdetail/index'));
const ProfilePage = React.lazy(() => import('./pages/profile'))
const MyItemsPage = React.lazy(() => import('./pages/myitems'))
const RugsdetailsLive = React.lazy(() => import('./pages/home/rugsdetailsLive'))
const BuyitnowPage = React.lazy(() => import('./pages/buyitnow'))
const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))
const CollectionsPage = React.lazy(() => import('./pages/collections'))
const NotificationPage = React.lazy(() => import('./pages/notification'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}
const stripePromise = loadStripe('pk_test_51PrIUYAVeh8r5vxo1x0bASBrPItFSzXJFLwEutdEJJOPrk99Xtc5mC5B0dUCCXURZ6mWnfjT1rUCreswiLUPP23E00OxrSFGIz');

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  const { user, getUnReadNotificationCount } = useAuth();
  // console.log("DEBUG user", user);
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    if (user) {
      getNotificationPermission(setTokenFound);
      onMessage(messaging, (payload) => {
        getUnReadNotificationCount();
        // console.log("Message received", payload);
        toast(
          (t) => (
            <Box display={"flex"} alignItems={"flex-start"} gap={3}>
              {/* <Avatar
                src={MessageImage}
                alt="NotificationIcon"
                variant="square"
              /> */}
              <BellOutline />
              <Box>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "25px" }}
                >
                  {payload.notification.title}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "15px" }}
                >
                  {payload.notification.body}
                </Typography>
              </Box>
            </Box>
          ),
          {
            style: { border: "1px solid #3B8D5C", borderRadius: "12px" },
            position: "top-right",
            duration: 3000,
          }
        );
        // alert(payload.notification.title);
        // alert(payload.notification.body);
      });
    }
  }, [user]);

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route element={<BlankLayout><Outlet /></BlankLayout>}>
          <Route path='/401' element={<Page401 />} />
          <Route path='/404' element={<Page404 />} />
          <Route element={<AuthLayout><Outlet /></AuthLayout>}>
            <Route element={<Guard guestGuard><Outlet /></Guard>}>
              {/* <Route path='/login' element={<LoginPage />}></Route> */}
              {/* <Route path='/forgot-password' element={<ForgotPassword />} /> */}
            </Route>
          </Route>
        </Route>

        <Route element={<UserLayout><Outlet /></UserLayout>}>
          <Route path='/' element={<HomePage />} />
          <Route path='/rug-listing' element={<RugListingPage />} />
          <Route path='/rug-listing/:id' element={<RugsdetailsPage />} />
          <Route path='/rugs-details-live/:id' element={<RugsdetailsLive />} />
          <Route path='/upcoming-auctions' element={<UpcomingAuctionsPage />} />
          <Route path='/upcoming-auctions/:id' element={<UpcomingAuctionsDetailPage />} />
          <Route path='/about-us' element={<AboutusPage />} />
          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='/terms-and-condition' element={<TermsandconditionPage />} />
          <Route path='/faq' element={<FaqDetailsPage />} />
          <Route path='/auction-blog' element={<AuctionBlogPage />} />
          <Route path='/auction-blog/:id' element={<AuctionsBlogDetailPage />} />
          <Route path='/my-items' element={<MyItemsPage />} />
          <Route path='/buy-it-now/:id' element={<BuyitnowPage />} />
          <Route path='/collections' element={<CollectionsPage />} />
          <Route path='/notification' element={<NotificationPage />} />
            <Route
              path='/profile'
              element={
                <Elements stripe={stripePromise}>
                  <ProfilePage />
                </Elements>
              }
            />
        </Route>

        <Route path='*' element={<Navigate to='/404' replace />} />

      </Routes>
    </Suspense>
  )
}

export default App

// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../auth.Slice'


console.log("authReducer",authReducer);

export const store = configureStore({

    reducer:{
        auth: authReducer,
    },
});
